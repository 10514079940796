import {
  Dispatch,
  Key,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableRowClickEvent,
  DataTableRowData,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable';
import {
  VirtualScrollerChangeEvent,
  VirtualScrollerLazyEvent,
  VirtualScrollerProps,
} from 'primereact/virtualscroller';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import type { SearchField } from 'components/EntitySearch/Models/SearchEntities';
import NoData from 'components/NoData';
import {
  ProfileSignalEventTypes,
  UISettings,
} from 'components/OBXUser/Model/Enums';
import { useLoadUserSettings } from 'components/OBXUser/Services/ProfileHooks';
import { ToastMessageRef } from 'components/ToastMessage';
import {
  WorksheetSignalMessageEventTypes,
  WorksheetStores,
} from 'components/Worksheets/Models/Enums';
import { useMutateWorksheet } from 'components/Worksheets/Services/WorksheetHooks';

import {
  SurveillanceEntityStatus,
  SurveillanceModeEnum,
} from '../../Models/Enums';
import { SearchRequest, SearchRequestPaged } from '../../Models/ReportsRequest';
import {
  SearchFiltersResponse,
  SurveillanceGridItem,
} from '../../Models/ReportsResponse';
import { EntityUpdateResponse } from '../../Services/AuditService';
import { useGetResults, useGetSurveillanceFilters } from '../../Services/Hooks';
import { SurveillanceSignalEventTypes } from '../../Services/SignalRSocket';
import {
  ContentComponentMessageUpdate,
  SelectedContentMessage,
} from '../RightPanel/Models';
import { isSearchItemsEqual } from '../SearchBar/Models/Parsers';
import TableFooter from '../TableFooter';

import { Content } from './Templates/Content';
import FilteringHeader from './Templates/FilteringHeader';
import { MediaChip } from './Templates/MediaChip';
import MultipleLineCell from './Templates/MultipleLineCell';
import StatusCell from './Templates/StatusCell';
import { UserCell } from './Templates/UserCell';

import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';
import { DoubleLineCounterpart } from 'helpers/DataTable/Templates/ColumnTemplates/DoubleLineCounterpart';
import { SingleLineBadge } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineBadge';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { numberFormatter } from 'helpers/Utils/formatters';
import { notNil, parsePropsToDateTime } from 'helpers/Utils/misc';
import { uniqueId } from 'helpers/Utils/string';
import { getAdditionalPropsFromSearchItems } from 'modules/CargoTracker/Components/CargoSearch/Models/Parsers';
import { isEntitySelectable } from 'modules/Surveillance/Helpers';
import { useResultsReducer } from 'modules/Surveillance/Services/SurveillanceHooks';

import {
  DEFAULT_GRID_ROW_HEIGHT,
  SELECTABLE_ROW_CLASS,
  SELECTED_ROW_CLASS,
} from 'models/shared/consts';
import eventBus from 'server/EventBus';

import './Results.scss';

interface ResultsProps {
  selectedMessages: SurveillanceGridItem[];
  setSelectedMessages: Dispatch<SetStateAction<SurveillanceGridItem[]>>;
  resultSelected: SurveillanceGridItem | null;
  setResultSelected: Dispatch<SetStateAction<SurveillanceGridItem | null>>;
  isLoadingWorksheet: boolean;
  isCompressedView: boolean;
  selectedSiblingMessages?: SelectedContentMessage[];
  searchItems?: SearchRequest;
  setSearchItems?: Dispatch<SetStateAction<SearchRequest>>;
  lastModified?: DateTime;
  activeWorksheetId?: string | null;
  activeWorksheetName?: string | null;
  toastRef?: RefObject<ToastMessageRef>;
  resultsMode?: SurveillanceModeEnum;
  isFooterVisible?: boolean;
  preloadedResults?: SurveillanceGridItem[] | null;
}

const PAGE_SIZE = 1000;

const Results = ({
  selectedMessages: selectedItems,
  setSelectedMessages: setSelectedItems,
  resultSelected,
  setResultSelected,
  isLoadingWorksheet,
  isCompressedView,
  selectedSiblingMessages,
  searchItems,
  setSearchItems,
  lastModified,
  activeWorksheetId,
  activeWorksheetName,
  toastRef,
  resultsMode,
  isFooterVisible = true,
  preloadedResults,
}: ResultsProps): ReactNode => {
  const { getSetting } = useLoadUserSettings();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [previousSearchItems, setPreviousSearchItems] = useState<
    SearchRequest | undefined
  >(searchItems);
  const [pagedSearchItems, setPagedSearchItems] = useState<SearchRequestPaged>({
    pageNumber: pageNumber,
    pageSize: PAGE_SIZE,
  });
  const [lastPageNumber, setLastPageNumber] = useState<number>(1);
  const [isSearchItemsChanged, setIsSearchItemsChanged] =
    useState<boolean>(false);
  const [isPageNumberChanged, setIsPageNumberChanged] = useState<boolean>(true);
  const [isCLDDChanged, setIsCLDDChanged] = useState(false);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(true);
  const [isLoadingTimeout, setIsLoadingTimeout] = useState(false);
  const [random, setRandom] = useState<string>(uniqueId()); // add random token as SWR returns first old data when switching tabs
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [recordsCount, setRecordsCount] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isIntermediateSelect, setIsIntermediateSelect] = useState(false);
  const [lastRenderedElement, setLastRenderedElement] = useState(0);
  const [activeCldd, setActiveCldd] = useState<string>(
    getSetting(UISettings.ACTIVE_CLDD)
  );

  const {
    data,
    error: resultsError,
    isValidating: isValidatingResults,
    isLoading: isLoadingResults,
  } = useGetResults(
    pagedSearchItems,
    activeWorksheetId,
    random,
    !preloadedResults?.length
  );

  const { data: filters } = useGetSurveillanceFilters(
    resultsMode !== SurveillanceModeEnum.Escalated
  );
  // Worksheet mutate loader
  const { mutateAdditionalProps } = useMutateWorksheet(
    WorksheetStores.Surveillance,
    activeWorksheetId ?? undefined
  );

  const dt = useRef<DataTable<SurveillanceGridItem[]>>(null);

  const updateVirtualScroller = useCallback(
    (selectedItemsLength: number): void => {
      // Hack for virtualscroller -> when footer is shown -> virtualscroller remains same height and footer is going below table and off screen
      // Height is calculated properly with "key: uniqueId()" in virtualScrollerOptions, but then on loading next pages, table is scrolled to top
      const virtualScrollerRef = dt.current
        ?.getVirtualScroller()
        .getElementRef() as RefObject<HTMLDivElement>;
      const virtualScrollerEl = virtualScrollerRef?.current;
      if (virtualScrollerEl) {
        const val = parseInt(virtualScrollerEl.style.height, 10); // i.e. 532px -> 532
        virtualScrollerEl.style.height =
          selectedItemsLength > 0 ? `${ val - 50 }px` : `${ val + 50 }px`;
      }
    },
    []
  );

  const clearSelection = useCallback(() => {
    setIsSelectAll(false);
    setIsIntermediateSelect(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onResultsDeleted = useCallback(() => {
    setIsSelectAll(false);
    updateVirtualScroller(0);
    setIsIntermediateSelect(false);
  }, [updateVirtualScroller]);

  const [results, setResults] = useResultsReducer(
    [],
    setResultSelected,
    setSelectedItems,
    setResultsCount,
    onResultsDeleted
  );

  const resetState = useCallback((): void => {
    setResults({ payload: { results: [] } });
    if (selectedItems.length > 0) {
      // update height of VS if footer with selected items was opened
      updateVirtualScroller(0);
    }
    clearSelection();
    setResultSelected(null);
    setPageNumber(1);
    setLastPageNumber(1);
    setResultsCount(0);
  }, [
    selectedItems.length,
    clearSelection,
    setResultSelected,
    updateVirtualScroller,
    setResults,
  ]);

  const handleUpdateData = useCallback((): void => {
    setIsLoadingTimeout(true);
    setTimeout(() => {
      resetState();
      const newCLDD = getSetting(UISettings.ACTIVE_CLDD);
      setActiveCldd(newCLDD);
      setIsCLDDChanged(true);
      setPagedSearchItems({
        ...searchItems,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        cldd: newCLDD,
      });
      setIsLoadingTimeout(false);
    }, 6000); // TODO: For now added timeout 6s until there is no SignalR when data will be updated
  }, [getSetting, resetState, searchItems]);

  const onEntityUpdated = useCallback(
    (e: CustomEvent<EntityUpdateResponse>): void => {
      const { status, items, worksheetId } = e.detail;

      if (worksheetId === activeWorksheetId) {
        setResults({
          payload: {
            resultsMode,
            status,
            results: items.map(i =>
              parsePropsToDateTime(i, ['startTime', 'stopTime'], {
                zone: 'local',
              })
            ),
          },
        });

        // Hide details if it's one of removed ones
        if (
          items.find(
            item =>
              resultSelected &&
              resultSelected.id === item.id &&
              resultSelected.partitionKey === item.partitionKey
          )
        ) {
          setResultSelected(null);
        }
      }
    },
    [
      activeWorksheetId,
      resultSelected,
      resultsMode,
      setResultSelected,
      setResults,
    ]
  );

  const onTranscriptionUpdated = useCallback(
    (e: CustomEvent<ContentComponentMessageUpdate>): void => {
      const indexResults: number = results.findIndex(d => d.id === e.detail.id);

      if (indexResults > -1) {
        setResults({
          payload: {
            results: replaceItemAt(
              results,
              {
                ...results[indexResults],
                content: e.detail.versions[0].text,
              },
              indexResults
            ),
          },
        });
      }
    },
    [results, setResults]
  );

  const addSiblings = useCallback(
    (newResults: SurveillanceGridItem[]): SurveillanceGridItem[] => {
      const flatResults: SurveillanceGridItem[] = [];

      newResults.forEach(el => {
        flatResults.push(el); // Add main element

        // Add siblings
        if (el.siblings && el.siblings.length > 0) {
          el.siblings.forEach(sibling =>
            flatResults.push({
              ...parsePropsToDateTime(sibling, ['startTime', 'stopTime'], {
                zone: 'local',
              }),
              isSibling: true,
              parent: el,
            })
          );
        }
      });

      return flatResults;
    },
    []
  );

  useEffect(() => {
    eventBus.on(ProfileSignalEventTypes.CLDD_UPDATED, handleUpdateData);
    eventBus.on(
      SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES,
      onEntityUpdated
    );
    eventBus.on(
      SurveillanceSignalEventTypes.SURVEILLANCE_TRANSCRIPTION_UPDATED,
      onTranscriptionUpdated
    );

    return (): void => {
      eventBus.remove(ProfileSignalEventTypes.CLDD_UPDATED, handleUpdateData);
      eventBus.remove(
        SurveillanceSignalEventTypes.SURVEILLANCE_ENTITY_UPDATES,
        onEntityUpdated
      );
      eventBus.remove(
        SurveillanceSignalEventTypes.SURVEILLANCE_TRANSCRIPTION_UPDATED,
        onTranscriptionUpdated
      );
    };
  }, [handleUpdateData, onEntityUpdated, onTranscriptionUpdated]);

  useEffect(() => {
    if (preloadedResults?.length) {
      setIsLoadingSearchResults(false);
      setResults({ payload: { results: addSiblings(preloadedResults) } });
    }
  }, [preloadedResults, addSiblings, setResults]);

  const isSearchEmpty = useMemo(
    () =>
      !searchItems?.searchRequestFields?.filter(
        srf =>
          srf.searchField !== EntitySearchFieldsEnum.SurveillanceReportState
      ).length &&
      !searchItems?.date &&
      !notNil(searchItems?.onlyAttachments) &&
      !notNil(searchItems?.onlyEmpty),
    [searchItems]
  );

  const appliedFilters = useMemo(
    () =>
      searchItems?.searchRequestFields?.reduce(
        (acc, value) => {
          switch (value.searchField) {
          case EntitySearchFieldsEnum.SurveillanceCompany:
            value.searchTerm && acc.company?.push(value.searchTerm);
            break;
          case EntitySearchFieldsEnum.SurveillanceMedia:
            value.searchTerm && acc.media?.push(value.searchTerm);
            break;
          }
          return acc;
        },
        { company: [], media: [] } as SearchFiltersResponse
      ),
    [searchItems?.searchRequestFields]
  );

  const isGridLoading = useMemo(
    () =>
      isLoadingResults ||
      isLoadingWorksheet ||
      isLoadingTimeout ||
      (isLoadingSearchResults && !isSearchEmpty) ||
      isValidatingResults,
    [
      isLoadingResults,
      isLoadingWorksheet,
      isLoadingTimeout,
      isLoadingSearchResults,
      isSearchEmpty,
      isValidatingResults,
    ]
  );

  useEffect(() => {
    // Clear results on search params change (check if params object really have changed)
    if (
      !isSearchItemsEqual(previousSearchItems, searchItems) &&
      !preloadedResults?.length
    ) {
      resetState();
      setIsSearchItemsChanged(true);
      setIsPageNumberChanged(true);
      if (!isSearchEmpty) {
        setIsLoadingSearchResults(true);
      }
      setPagedSearchItems({
        ...searchItems,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        cldd: activeCldd,
      });
      setPreviousSearchItems(searchItems);
    }
    // eslint-disable-next-line
  }, [
    // previousSearchItems was removed from dependency as it goes into loop in some cases
    activeCldd,
    activeWorksheetId,
    isCLDDChanged,
    isSearchEmpty,
    resetState,
    searchItems,
  ]);

  useEffect(() => {
    if (
      data?.results?.length &&
      !isValidatingResults &&
      (isSearchItemsChanged || isPageNumberChanged || isCLDDChanged)
    ) {
      // Add incoming data to end of results
      const newData =
        resultsMode === SurveillanceModeEnum.Escalated
          ? addSiblings(data.results)
          : data.results;

      setIsSearchItemsChanged(false);
      setIsPageNumberChanged(false);
      setIsCLDDChanged(false);
      setIsLoadingSearchResults(false);
      setLastPageNumber(data.totalPages ?? 1);
      setResultsCount(data?.totalResults ?? 0);
      setResults({
        payload: { results: currentResults => [...currentResults, ...newData] },
      });
    } else if (data?.results?.length === 0) {
      resetState();
      setIsSearchItemsChanged(false);
      setIsPageNumberChanged(false);
      setIsCLDDChanged(false);
      setIsLoadingSearchResults(false);
      setResults({ payload: { results: [] } });
    }
    if (resultsError) {
      setIsLoadingSearchResults(false);
    }
  }, [
    data,
    resultsMode,
    isCLDDChanged,
    isValidatingResults,
    isLoadingSearchResults,
    isPageNumberChanged,
    isSearchItemsChanged,
    pageNumber,
    resultsError,
    resetState,
    setPageNumber,
    addSiblings,
    setResults,
  ]);

  useEffect(() => {
    // On Tab change (Search results / reviewed) update random to fetch new data
    setRandom(uniqueId());
    // Unselect items if selected
    if (selectedItems.length) {
      clearSelection();
      updateVirtualScroller(0);
    }
    // eslint-disable-next-line
  }, [resultsMode]);

  useEffect(() => setRecordsCount(resultsCount), [resultsCount]);

  const loadLazy = useCallback(
    (event: VirtualScrollerLazyEvent): void => {
      const { last } = event;

      // If last element in scrolled list reached last loaded element -> increase page number and load next page
      if (
        (last as number) > 0 &&
        (last as number) >= results?.length &&
        results.length < resultsCount &&
        pageNumber < lastPageNumber &&
        data !== undefined
      ) {
        setPagedSearchItems({
          ...searchItems,
          pageNumber: pageNumber + 1,
          pageSize: PAGE_SIZE,
          cldd: activeCldd,
        });
        setPageNumber(pn => pn + 1);
        setIsPageNumberChanged(true);
      }
    },
    [
      activeCldd,
      data,
      lastPageNumber,
      pageNumber,
      results.length,
      resultsCount,
      searchItems,
    ]
  );

  const onSelectionChange = useCallback(
    (
      e: DataTableSelectionMultipleChangeEvent<SurveillanceGridItem[]>
    ): void => {
      // In DataTable only rendered elements ale selected on selectAll
      // So when results are 1000, only ~30-43 are selected, depends on screen height
      // If we would like to manually select all "setSelectedItems(results)" - Primereact can't handle it anyway.
      const isAllSelected =
        (e.type === 'all' && e.value.length > 0) ||
        results.length === e.value.length;
      setSelectedItems(e.value);
      setIsSelectAll(isAllSelected);

      if (selectedItems.length === 0 || e.value.length === 0) {
        // Only on change from/to empty selection
        updateVirtualScroller(e.value.length);
      }

      if (e.type === 'all' && e.value.length > 0) {
        setLastRenderedElement(e.value.length);
      }

      if (
        e.type === 'all' || // If user clicks "all" checkbox (both on or off)
        (e.type === 'checkbox' && e.value.length === lastRenderedElement) || // If user clicks checkbox and all rendered are selected
        (e.type === 'checkbox' && e.value.length === 0)
      ) {
        // If deselects all
        setIsIntermediateSelect(false);
      } else if (
        !isAllSelected &&
        e.type === 'checkbox' &&
        (e.value.length < selectedItems.length || e.value.length > 0)
      ) {
        // If user clicks checkbox and selectedItems are decreased
        setIsIntermediateSelect(true);
      }
    },
    [
      lastRenderedElement,
      results.length,
      selectedItems.length,
      setSelectedItems,
      updateVirtualScroller,
    ]
  );

  const onScrollIndexChange = useCallback(
    (e: VirtualScrollerChangeEvent): void => {
      setLastRenderedElement(e.last as number);
      setIsIntermediateSelect(
        (e.last as number) > selectedItems.length && selectedItems.length > 0
      );
    },
    [selectedItems.length]
  );

  const rowClassName = useCallback(
    (
      d: DataTableRowData<SurveillanceGridItem[]>
    ): object | string | undefined => {
      const classNames: Record<string, boolean> = {};
      if (resultSelected && d.id === resultSelected.id) {
        //	When a row has been clicked on and the edit/add panel is active
        //	we want the row to render in it's selected state
        classNames[SELECTED_ROW_CLASS] = true;
      } else {
        classNames[SELECTABLE_ROW_CLASS] = true;
      }

      if (
        resultsMode === SurveillanceModeEnum.Escalated &&
        (d.status === SurveillanceEntityStatus.Escalated ||
          d.parent?.status === SurveillanceEntityStatus.Escalated)
      ) {
        classNames.newItem = true;
      }

      return classNames;
    },
    [resultsMode, resultSelected]
  );

  const onFilterItemChanged = useCallback(
    (
      entitySearchField: EntitySearchFieldsEnum,
      entitySearchValue: string,
      value: boolean
    ): void => {
      if (setSearchItems) {
        let searchRequestFields: SearchField[] | undefined;
        if (value) {
          searchRequestFields = [
            ...(searchItems?.searchRequestFields || []),
            {
              searchField: entitySearchField,
              searchTerm: entitySearchValue,
              metaData: [],
              external: true,
            },
          ];
          setSearchItems({
            ...searchItems,
            searchRequestFields,
          });
        } else {
          searchRequestFields = searchItems?.searchRequestFields
            ? removeItemAt(
              searchItems.searchRequestFields,
              searchItems.searchRequestFields.findIndex(
                item => item.searchTerm === entitySearchValue
              )
            )
            : searchItems?.searchRequestFields;
          setSearchItems({
            ...searchItems,
            searchRequestFields,
          });
        }

        const additionalProps = getAdditionalPropsFromSearchItems({
          ...searchItems,
          searchRequestFields,
          // add `updatedAt` so it's immidiatelly set in the event.dispatch's payload
          updatedAt: new Date().toUTCString(),
        });

        // Update worksheet
        mutateAdditionalProps(additionalProps);
        // Notify SearchBar about change in worksheet
        eventBus.dispatch(WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED, {
          worksheetId: activeWorksheetId,
          additionalSearchProperties: additionalProps,
        });
      }
    },
    [searchItems, setSearchItems, mutateAdditionalProps]
  );

  const isRowSelectable = useCallback(
    (event: { data: SurveillanceGridItem; index: number }): boolean | null => {
      if (!event.data) {
        return null;
      }

      const { status } = event.data;

      return isEntitySelectable(resultsMode, status);
    },
    [resultsMode]
  );

  const companyHeaderItems = useMemo(
    () =>
      filters?.company?.map(filterValue => ({
        label: filterValue,
        value: Boolean(
          appliedFilters?.company?.find(company => company === filterValue)
        ),
        onChanged: onFilterItemChanged.bind(
          this,
          EntitySearchFieldsEnum.SurveillanceCompany,
          filterValue
        ),
      })),
    [appliedFilters, filters, onFilterItemChanged]
  );
  const mediaHeaderItems = useMemo(
    () =>
      filters?.media?.map(filterValue => ({
        label: filterValue,
        value: Boolean(
          appliedFilters?.media?.find(media => media === filterValue)
        ),
        onChanged: onFilterItemChanged.bind(
          this,
          EntitySearchFieldsEnum.SurveillanceMedia,
          filterValue
        ),
      })),
    [appliedFilters, filters, onFilterItemChanged]
  );

  return (
    <>
      {isSearchEmpty && !preloadedResults?.length && (
        <NoData
          header={
            'It looks like no records have been added yet.\nPlease start your search to create a report'
          }
          icon='iconoir-search'
        />
      )}
      {(!isSearchEmpty ||
        isLoadingResults ||
        (isLoadingSearchResults && !isSearchEmpty) ||
        Boolean(preloadedResults?.length)) && (
        <DataTable
          ref={dt}
          className={clsx('surveillance-results__table grow-to-fill', {
            'surveillance-results__table-compressed': isCompressedView,
            'surveillance-results__table-escalated':
              !isCompressedView &&
              resultsMode === SurveillanceModeEnum.Escalated,
            'surveillance-results__table-extended':
              !isCompressedView && resultsMode === SurveillanceModeEnum.Results,
            'surveillance-results__table-reviewed':
              resultsMode === SurveillanceModeEnum.Reviewed,
          })}
          dataKey='id'
          loading={isGridLoading}
          value={results}
          emptyMessage={(): ReactNode => (
            <div>
              <strong>No results</strong>
              <br />
              There are no transcriptions, calls or messages that match this
              search criteria
            </div>
          )}
          scrollable
          scrollHeight='flex'
          selectionMode='checkbox'
          isDataSelectable={isRowSelectable}
          selection={selectedItems}
          onSelectionChange={onSelectionChange}
          onRowClick={(e: DataTableRowClickEvent): void =>
            setResultSelected(e.data as SurveillanceGridItem)
          }
          pt={{
            bodyRow: {
              style: { height: 'auto' },
            },
          }}
          virtualScrollerOptions={
            {
              autoSize: true,
              // itemSize is required to display proper amount of items
              // but row height owerwritten with pt options bodyRow style
              itemSize: DEFAULT_GRID_ROW_HEIGHT,
              lazy: true,
              step: 10,
              // render more items to avoid items blinking
              numToleratedItems: isCompressedView ? 20 : 40,
              onLazyLoad: loadLazy,
              loading: isLoadingResults,
              onScrollIndexChange: onScrollIndexChange,
            } as VirtualScrollerProps & { key: Key }
          }
          footer={
            selectedItems?.length ? (
              <TableFooter
                selectedItems={selectedItems}
                clearSelection={clearSelection}
                activeWorksheetId={activeWorksheetId}
                activeWorksheetName={activeWorksheetName}
                selectedSiblingMessages={selectedSiblingMessages}
                searchItems={searchItems}
                recordsCount={recordsCount}
                isSelectAll={isSelectAll}
                toastRef={toastRef}
                resultsMode={resultsMode}
              />
            ) : (
              <></>
            )
          }
          rowClassName={rowClassName}
          showSelectionElement={rowData => !rowData.isSibling}
        >
          {/* For testing: <Column header="#" field="elementCounter" /> */}
          <Column
            selectionMode='multiple'
            frozen
            headerClassName={clsx({ intermediate: isIntermediateSelect })}
          />
          <Column
            headerClassName={
              isCompressedView
                ? 'surveillance-results__table-header-compressed'
                : ''
            }
            header={
              resultsMode !== SurveillanceModeEnum.Escalated ? (
                <FilteringHeader
                  className='surveillance-results__table-header'
                  menuClassName='surveillance-results__table-header-context-menu'
                  label={isCompressedView ? 'Filter' : 'Company'}
                  items={
                    isCompressedView
                      ? {
                        company: companyHeaderItems || [],
                        media: mediaHeaderItems || [],
                      }
                      : companyHeaderItems
                  }
                />
              ) : (
                'Company'
              )
            }
            field='company'
            body={(data, config): ReactNode => (
              <MultipleLineCell
                data={data}
                field={config.field}
                header='Company'
                isDefaultBehavior={!isCompressedView}
                className='multi-line-cell'
              />
            )}
          />
          {resultsMode === SurveillanceModeEnum.Results && (
            <Column
              header='' // Escalate row badges
              field='status'
              body={(data, config): ReactNode =>
                SingleLineBadge(data, config, [
                  {
                    expectedValue: SurveillanceEntityStatus.Escalated,
                    icon: 'E',
                  },
                  {
                    expectedValue: SurveillanceEntityStatus.ActiveWithComments,
                    icon: <i className='icon--tiny iconoir-chat-lines' />,
                  },
                ])
              }
            />
          )}
          <Column
            header='User'
            field='userName,email,number'
            body={(data, config): ReactNode => (
              <MultipleLineCell
                data={data}
                field={config.field}
                header='User'
                isDefaultBehavior={!isCompressedView}
                className='multi-line-cell'
                customCell={UserCell(data, config)}
              />
            )}
          />
          <Column
            header='Counterpart'
            field='counterParty,groupName'
            body={(data, config): ReactNode => (
              <MultipleLineCell
                data={data}
                field={config.field}
                header='Counterpart'
                isDefaultBehavior={!isCompressedView}
                className='multi-line-cell multi-line-cell__child-column-direction'
                customCell={DoubleLineCounterpart<SurveillanceGridItem>(
                  data,
                  config
                )}
              />
            )}
          />
          <Column
            header={
              resultsMode !== SurveillanceModeEnum.Escalated ? (
                <FilteringHeader
                  className='surveillance-results__table-header'
                  menuClassName='surveillance-results__table-header-context-menu'
                  label='Media'
                  items={mediaHeaderItems}
                />
              ) : (
                'Media'
              )
            }
            field='provider,media'
            body={(data, config): ReactNode => (
              <MultipleLineCell
                data={data}
                field={config.field}
                isDefaultBehavior={!isCompressedView}
                className='multi-line-cell'
                customCell={MediaChip(data, config)}
              />
            )}
          />
          <Column
            header='Date'
            field='startTime'
            body={(data, config): ReactNode => (
              <MultipleLineCell
                data={data}
                header='Date'
                field={config.field}
                isDefaultBehavior={!isCompressedView}
                className='multi-line-cell'
                customCell={ReadableDate<SurveillanceGridItem>(
                  data,
                  config,
                  'dd LLL yyyy, HH:mm:ss'
                )}
              />
            )}
          />
          <Column
            header='Content'
            field='content,isAttachment'
            className='content-cell'
            body={(data, config) => (
              <MultipleLineCell
                data={data}
                field={config.field}
                header={'Content'}
                isDefaultBehavior={!isCompressedView}
                className='multi-line-cell brakeLines'
                customCell={Content(data, config)}
              />
            )}
          />
          {resultsMode === SurveillanceModeEnum.Escalated && (
            <Column header='Status' field='status' body={StatusCell} />
          )}
        </DataTable>
      )}
      {isFooterVisible && (
        <footer className='surveillance-footer'>
          <div className='surveillance-active-ws'>
            Active workspace: {activeCldd ?? '-'}
          </div>
          <div className='surveillance-last-updated'>
            {numberFormatter(resultsCount)} results found, Last updated{' '}
            {lastModified?.toFormat('HH:mm:ss LLL dd, yyyy') ?? '-'}
          </div>
        </footer>
      )}
    </>
  );
};

export default Results;
export { Results };
